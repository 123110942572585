/**
 * wallop.css
 *
 * @fileoverview Default styles for wallop – recommended
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

/*
  This is the top-level selector

  It should be relative positioned
  to allow the children to be positioned absolutely
  relative to this
 */
.Wallop {
  position: relative;
  }

/*
  This element groups all the items, but not the buttons

  It's a recommendation but it's very likely
  you'll want to hide any overflow from the items
  Especially when doing animations such as scale
 */
.Wallop-list {
  position: relative;
  overflow: hidden;
}

/*
  This is the item element

  By default, they are all hidden and
  positioned absolute
  I recommend always having .Wallop-item--current
  in your markup by default (probably on the first element)
 */
.Wallop-item {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/*
  This is the current item element

  All we do here, is make it visible again reset
  the position to static. Could also be relative
 */
.Wallop-item--current {
  visibility: visible;
  position: relative;
  }
/**
 * wallop--fade.css
 *
 * @fileoverview Fade animation for wallop
 *
 * @author Pedro Duarte
 * @author http://pedroduarte.me/wallop
 */

 .Wallop--fade .Wallop-item--hidePrevious,
 .Wallop--fade .Wallop-item--hideNext {
  visibility: visible;
  animation: fadeOut 900ms cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/*
  In order to fade out properly we need to make sure
  that the item that is going to be the previous one
  has a higer z-index that the next one
 */
.Wallop--fade .Wallop-item--hidePrevious,
.Wallop--fade .Wallop-item--hideNext { z-index: 2; }
.Wallop--fade .Wallop-item--showPrevious,
.Wallop--fade .Wallop-item--showNext { z-index: 1; }

 /*==========  FADE ANIMATIONS  ==========*/
@-webkit-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-moz-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@-ms-keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}


/* Demo styles for pagination */
.Wallop-pagination {
  margin:- $baseline * 2 0 0 0;
  position:relative;
  text-align: center;
  z-index:10;
  }

.Wallop-dot {
    background-color: #fff;  
    border:1px solid #fff;
    border-radius: 50%;
    height: $baseline / 2;
    margin: $baseline / 4;  
    padding: 0;
    text-indent: -9999px;
    transition:background-color .25s ease;
    width: $baseline / 2;
    -webkit-appearance: none; 
    &:hover {
    background-color: transparent;
        border:1px solid #fff;
        } 
    }

.Wallop-dot--current {
    background-color: transparent;
    }
