.show-for-small-only {
	@media (min-width: $bp-medium) {
		display: none !important;
		}
	}
		
.show-for-medium-up {
	@media (max-width: 47.95em) {
		display: none !important;
		}
	@media (min-width: $bp-medium) {
		display: inherit !important;
		}
	}
	
.show-for-medium-only {
	@media (max-width: 47.95em) {
		display: none !important;
		}
	@media (min-width: $bp-medium) {
		display: inherit !important;
		}
	@media (min-width: $bp-large) {
		display: none !important;
		}	
	}

.show-for-large-up {
	@media (max-width: 59.95em) {
		display: none !important;
		}
	}
	
.show-for-large-only {	
	@media (max-width: 59.95em) {
		display: none !important;
		}		
	@media (min-width: $bp-large) {
		display: inherit !important;
		}		
	@media (min-width: 75em) {
		display: none !important;
		}	
	
	}
	
.show-for-xlarge-up {
	@media (max-width: 74.95em) {
		display: none !important;
		}
	}
	
.show-for-xlarge-only {
	@media (max-width: 74.95em) {
		display: none !important;
		}
	}
	

	
.hide-for-small-only {
	display:none;
	@media (min-width: $bp-medium) {
		display: inherit !important;
		}
	}
		
.hide-for-medium-up {
	@media (min-width: $bp-medium) {
		display: none !important;
		}
	}
	
.hide-for-medium-only {
	@media (min-width: $bp-medium) {
		display: none !important;
		}
	@media (min-width: $bp-large) {
		display: inherit !important;
		}	
	}

.hide-for-large-up {
	@media (max-width: 59.95em) {
		display: none !important;
		}
	}
	
.hide-for-large-only {			
	@media (min-width: $bp-large) {
		display: none !important;
		}		
	@media (min-width: 75em) {
		display: inherit !important;
		}		
	}
	
.hide-for-xlarge-up {
	@media (min-width: 74.95em) {
		display: none !important;
		}
	}
	
.hide-for-xlarge-only {
	display:none;
	@media (max-width: 74.95em) {
		display: inherit !important;
		}
	}


.hide-on-small {
	@media (max-width: 48em) {
		@include visuallyhidden;
		}
	}

@media (min-width: $bp-large) {
	.hide-on-large {
		@include visuallyhidden;
		}
	}
	