table {
    border-bottom:1px solid $primary-color;
    border-top:$baseline / 6 solid $primary-color;
    display: table;    
    margin-bottom:$baseline * 2;
    width:100%;
    @media screen and (max-width:$bp-large) {
        display: block;
        }
    @media (min-width: $bp-medium) {
        line-height:$baseline / 1.5;
        }
    th {
        background-color:$light-grey-1;
        color:$dark-grey-2;
        font-weight:600;
        padding:$baseline / 2 2%;
        text-align:left;
        vertical-align:bottom;
        }
    td {
        border-bottom:1px solid $light-grey-1;
        color:$dark-grey-1;
        padding:$baseline / 2 2%;
        text-align:left;
        }
        
    tr:nth-child(odd) td {
        background-color: #FFF
        }
        
     tr:nth-child(even) td {
         background-color: $light-grey-1;
         } 
    }

[data-th]:before { 
    @media screen and (max-width:$bp-large) {
        @include delta;
        color:$dark-grey-1;
        content: attr(data-th) ": ";
        display:block;       
        font-weight:400;
        left:$gutter;
        margin-bottom: $baseline / 4;
        top:.5rem;
        }
    }



thead {
    display: table-header-group;
    @media screen and (max-width:$bp-large){
        max-height:0;
        overflow: hidden;
        position: absolute;
        }
    }

tbody {
    display: table-row-group;
    @media screen and (max-width:$bp-large) {
        display: block;
        }
    }

tr {
    display: table-row;
    @media (max-width:$bp-large){
        display: block;
        position: relative;
        padding-top:.75rem;
        padding-bottom:.75rem;
        padding-right:$gutter;
        width:100%;
    }

}
th,
td {
    display: table-cell;
    text-align:left;
        @media screen and (max-width:$bp-large){
            display: block;
        }
}
td {
    @media screen and (max-width:$bp-large) {
        display:block;
        width:100%;
        min-height:1.5rem;
        padding-left:4rem;
        position: relative;
    }
    vertical-align: middle;
}
