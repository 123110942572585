// Animation
//
// Styleguide 1.0

// Lazysizes animation
.lazyload,
.lazyloading {
	opacity: 0;
	}

.lazyloaded {
	opacity: 1;
	transition: opacity ease-out 0.3s; 
	}


/* Animations http://devinvinson.com/delayed-fade-in-effects-with-css/
-------------------------------------------------------------- */
@keyframes fade-in {
  from { 
    opacity:0;
    } 
  to { 
    opacity:1;
    }
  }

.fade-in { 
  opacity:0;
  opacity: 1 \9; /*just in case ie*/
  animation:fade-in ease-out 1;
  animation-fill-mode:forwards;
  transform-origin: 50% 50%;
  animation-duration:.75s;
  animation-delay:0;
  }

.fade-in--2 { 
  animation-delay: 1.25s;
  }

.fade-in--3 { 
  animation-delay: 1.5s;
  }

.fade-in--4 { 
  animation-delay: 1.75s;
  }

.fade-in--5 { 
  animation-delay: 2s;
  }

@keyframes fade-in-up {
  from { 
    opacity:0;
    transform:  translate(0, 20px)  
    } 
  to { 
    opacity:1;
    transform:  translate(0,0) 
    }
  }


.fade-in-up { 
  animation:fade-in-up ease-out 1;
  animation-fill-mode:forwards;
  animation-duration:.4s;
  animation-delay: .25s;
  opacity:0;
  opacity: 1 \9; /*just in case ie*/  
  transform-origin: 50% 50%;  
  }

.fade-in-up--1 { 
  animation-delay: .5s;
  }

.fade-in-up--2 { 
  animation-delay: .75s;
  }

.fade-in-up--3 { 
  animation-delay: 1s;
  }

.fade-in-up--4 { 
  animation-delay: 1.5s;
  }

.fade-in-up--5 { 
  animation-delay: 2s;
  }


@keyframes fade-in-out {
  0% { 
    opacity:0;
    } 
  25% { 
    opacity:1;
    }
  75% { 
    opacity:1;
    }
  100% { 
    opacity:0;
    }
  }


.fade-in-out { 
    opacity:0;
    opacity: 1 \9; /*just in case ie*/
    animation:fade-in-out ease-out 1;
    animation-fill-mode:forwards;
    transform-origin: 50% 50%;
    animation-duration:5s;
    animation-delay: 1.5s;
    }

.fade-in-out--1 { 
    animation-delay: 6.5s;
    }

.fade-in-out--2 { 
    animation-delay: 11.5s;
    }

.fade-in-out--3 { 
    animation-delay: 16.5s;
    }


@keyframes tabs-fade-in-up {
  from { 
    opacity:0;
    transform:  translate(0,20px)  
    } 
  to { 
    opacity:1;
    transform:  translate(0,0) 
    }
  }  

.tabs__section[aria-hidden="false"] { 
  opacity:0;
  opacity: 1 \9; /*just in case ie*/
  animation:tabs-fade-in-up ease-out 1;
  animation-fill-mode:forwards;
  transform-origin: 50% 50%;
  animation-duration:.5s;
  animation-delay: .33s;
  }

.tabs-accordion__section[aria-hidden="false"] { 
  @media (min-width: $bp-medium) {
    opacity:0;
    opacity: 1 \9; /*just in case ie*/
    animation:tabs-fade-in-up ease-out 1;
    animation-fill-mode:forwards;
    transform-origin: 50% 50%;
    animation-duration:.5s;
    animation-delay: .33s;
    }
  }

@keyframes fade-in-on-scroll {
  from { 
    opacity:0;
    transform:  translate(0,24px)  
    } 
  to { 
    opacity:1;
    transform:  translate(0,0) 
    }
  }  

 .fade-in-on-scroll {
    opacity:0;
    &.is-scrolled-in {
      opacity:0;
      opacity: 1 \9; /*just in case ie*/
      animation:fade-in-on-scroll ease-out 1;
      animation-fill-mode:forwards;
      transform-origin: 50% 50%;
      animation-duration:1s;
      animation-delay: 0;
      }
  }



@keyframes fade-in-up-on-scroll {
  from { 
    opacity:0;
    transform:  translate(0,20px)  
    } 
  to { 
    opacity:1;
    transform:  translate(0,0) 
    }
  }  

 .fade-in-up-on-scroll {
    opacity:0;
    &.is-scrolled-in {
      animation:fade-in-up-on-scroll linear 1;
      animation-delay: 0;      
      animation-duration:.5s;
      animation-fill-mode:forwards; 
      opacity:0;
      opacity: 1 \9; /*just in case ie*/      
      transform-origin: 50% 50%;

      }
  }

 .fade-in-up-on-scroll--1 {
    animation-delay: 2s;
    }


