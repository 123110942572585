/* Navigation
-------------------------------------------------------------- */
.nav-btn-close {
    visibility: hidden;
    opacity:0;
    position:absolute;
    top:0;
    bottom:0;
    }

.btn-hamburger {
    @include ir;
    border-top:3px solid $off-black;
    height:$baseline * 1.25;   
    position: absolute;
    top:$baseline / 1.5;
    right:$baseline / 2;
    width:$baseline * 1.25;
    z-index:5;
    @media (min-width: $bp-medium) {
        right:calc( #{$gutter} + #{$baseline / 2}) ;
        top:$baseline / 1.25;
        }

    @media (min-width: $bp-large) {
        display:none;
        }
        &:before,
        &:after {
            background:$off-black;
            content:'';            
            height:3px;
            left:0;            
            line-height:4px;
            position: absolute;
            width:$baseline * 1.25; 
            z-index:1;                           
            }
        &:before {
            top:6px;
            }
        &:after {
            top:15px;
            }
    }

.on--navigation {
	@media (max-width: 59.995em) {
	    .btn-hamburger {
	        border-top:none;
	        transition: border-color 100ms ease-out;
	        z-index:1001;
	        &:before,
	        &:after {
	            background-color:$off-black;
	            transition: transform 100ms ease-out, background-color 100ms ease-out;
	        	}
	        &:before {
	            transform: rotate(45deg);
	        	}
	        &:after {
	            transform: rotate(-45deg) translateX(7px) translateY(-7px);
	        	}
	        }
	    }
    .nav-btn-close {
        width: 100%;
        left: -100%;
        visibility:visible;
    	}
	}
.animating--navigation .btn-hamburger {
    border-top:4px solid $off-black;
    transition: border-color 100ms ease-in, border-top 100ms ease-in;
    &:before,
    &:after {
        transition: transform 100ms ease-in, border-top 100ms ease-in;
        transform: rotate(0);
    	}
	}


// Offcanvas menu animation only
@media(max-width: 59.995em) {
    .nav-primary {
        background-color: #fff;
        bottom: 0;
        display: none;
        left: 0;
        padding:$baseline * 3 $gutter 0 $gutter;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 20;
        text-align: center;
        transition: all ease-out 1s;
        }
    .on--navigation .nav-primary {
        @media (max-width: 59.995em) {
            animation:fade-in ease 1;
            animation-fill-mode:forwards;
            animation-duration:.5s;
            animation-delay:0;            
            display:block;
            opacity:0;
            opacity: 1 \9; /*just in case ie*/
            transform-origin: 50% 50%;
            .nav-primary__item {
                opacity:0;
                opacity: 1 \9; /*just in case ie*/
                animation:fade-in-up ease 1;
                animation-fill-mode:forwards;
                transform-origin: 50% 50%;
                animation-duration:.5s;
                animation-delay: .1;
                &:nth-child(1) {
                    animation-delay: .1s;
                    }
                &:nth-child(2) {
                    animation-delay: .2s;
                    }
                &:nth-child(3) {
                    animation-delay: .3s;
                    }
                &:nth-child(4) {
                    animation-delay: .4s;
                    }
                &:nth-child(5) {
                    animation-delay: .5s;
                    }
                &:nth-child(6) {
                    animation-delay: .6s;
                    }
                }
            }
        }
    .on--navigation {
        .on--navigation__animate {
            transform:translateX(0);
            transition:transform 500ms ease;
            @media(min-width: $bp-medium) {
                transform:translateX(-55%);
                }
            }
        }
    .on--navigation .nav-primary {
        display:block;
        overflow-y: auto;
        }
    .animating--navigation {
        .nav-primary {
            transform: translateX(0);
            transition:transform 500ms ease;
            }
        }
    }

//primary nav

.nav-primary {
	@media (min-width: $bp-large) {
		left:0;
//        margin-top: - $baseline / 3;
        position:fixed;
        top:66vh;
        width:calc(#{$site-header-width} - #{$baseline * 2});
        width:$site-header-width;
		}
	}

.nav-primary__list {
	display:block;
	}

.nav-primary__item {
	display:block;
	}


.nav-primary__link {
	@include tera;
	color:$dark-grey-3;
    font-family:$primary-font-light;
	display:block;
	padding:$baseline / 2 0;
    text-align:right;
	width:100%;
    @media (min-width: $bp-large) {
        @include gamma;
        font-family:$primary-font;
        font-weight:600;
        padding:$baseline / 3 0;
        text-align:center;
        }
    &:hover,
    &:focus {
    	color:$mid-grey-3;
    	text-decoration:none;
    	}
	}

.nav-inline-wrap {
	&.is--stuck {
        @media (min-width: $bp-medium) {
            background-color:rgba(255,255,255, .95);
            left:0;
            position:fixed;
            top:0;
            z-index:100;
            width:100%;
            }
        @media (min-width: $bp-large) {
            left:$site-header-width;
            width:calc(100% - #{$site-header-width});
            }
		.nav-inline {
		    margin: 0 auto;
		    max-width: 1800px;
		    @media (min-width: $bp-large) {
		        padding: 0 $gutter * 2;
		        }
		    @media (min-width: $bp-xlarge) {
		        padding: 0 $gutter * 8 0 $gutter * 4;
		        }
			}
		}
	}

.nav-inline {
	display:none;
    @media (min-width: $bp-large) {
		@include clearfix;
		border-bottom:1px solid $light-grey-2;
		border-top:1px solid $light-grey-2;
		display:block;
        margin:0 0 $baseline * 2 0;
        }
	}

.nav-inline--centered {
    text-align:center;
    .nav-inline__link {
        display:inline-block;
        float:none;
        }
    }

.nav-inline__text {
    @include epsilon;
    color:$mid-grey-3;
    display:block;
    height:$baseline * 2;
    line-height:$baseline * 2;
    float:left;
    margin:0 $baseline / 2;
    padding:0 $baseline 0 0;
    position:relative;
    text-transform:uppercase;
    &:after {
        background-color:$light-grey-3;
        bottom:$baseline / 2;
        display:block;
        content:'';
        position:absolute;
        right:0;
        top:$baseline / 2;
        width:1px
        }
    }

.nav-inline__link {
	@include delta;
	color:$dark-grey-1;
	display:inline-block;
	height:$baseline * 2;
	line-height:$baseline * 2;
	float:left;
	overflow:visible;
	margin:0 $baseline / 2;
	position:relative;
    text-transform:uppercase;
	transition:color .25s ease;

    @media (min-width: $bp-medium) {
        height:$baseline * 3;
        line-height:$baseline * 3;
        }

    @media (min-width: 75.1em) {

        }

	&:after {
		background-color:transparent;
		bottom:0;
		content:'';
		height:$baseline / 12;
		left:0;
		position:absolute;
		right:0;
		transition:background-color .25s ease;
		}

	&.active {
		color:$dark-grey-1;
		&:after {
			background-color:$dark-grey-1;
			}
		}

	&:hover {
		color:$dark-grey-1;
		&:after {
			background-color:$dark-grey-1;
			bottom:0;
			content:'';
			height:$baseline / 12;
			left:0;
			position:absolute;
			right:0;
			}
		}
	}

.nav-inline__link--narrow {
    height:$baseline * 2;
    line-height:$baseline * 2;
    margin:0 $baseline / 2;
    }

.nav-inline__link--no-hover {
    margin:0;
    &:hover {
        &:after {
            content:none;
            }
        }
    }
    