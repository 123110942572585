.home-hero-wrap {
	height:80vh;
	position:relative;
	@media (min-width: $bp-medium) {
		height:95vh;
		}
	}

.home-hero {
	background-position:50% 50%;
	background-size:cover;
	height:80vh;
	position:relative;
	@media (min-width: $bp-medium) {
		height:95vh;
		}
	} 

.home-hero__bg-img {
	background-position:50% 50%;
	background-size:cover;
	bottom:0;
	left:0;
	position:absolute;
	right:0;
	top:0;
	z-index:1;
	@media (min-width: $bp-medium) {
		height:95vh;
		}
	}

@keyframes scaling {
	from {
		transform: scale(1.0);
		}
	to {
		transform: scale(1.05);
		}
	}

.Wallop-item--current .home-hero__bg-img {
	animation: scaling 9 1;	
	}

.home-hero__inner {
	padding-top:50vh;
	position:relative;
	z-index:2;
	@media (min-width: $bp-medium) {
		padding-top:66vh;
		}
	}

.home-hero__title {
	background: #fff;
	background-color:transparent;
	box-decoration-break: clone;
	color:#fff;
	display: block;	
	font-family:$primary-font-light;
	margin-bottom:$baseline;
	font-size:1.2rem;
	line-height:$baseline;
	margin-bottom:$baseline / 2;
	padding-bottom:$baseline / 4;

	@media (min-width: $bp-medium) {
		@include giga;
		margin-bottom:$baseline;
		}

	}

.home-hero__caption {
	@include epsilon;
	color:#fff;
	overflow:visible;
	padding:0 0 0 $baseline / 2;
	position:relative;
	&:before {
		background-color:rgba(255,255,255, .5);
		content:'';
		height:$baseline;
		left:0;		
		position:absolute;
		top:0;
		width:1px;
		}
	}



.wallop__nav,
.peppermint__nav {
	@include clearfix;
	bottom:$baseline;
	display:none;
	position:absolute;
	right:$baseline;
	z-index:101;
	}

.wallop__nav--previous,  
.wallop__nav--next,
.peppermint__nav--previous, 
.peppermint__nav--next {
	@include mega;
	background-color:rgba(0,0,0, .25);
	background-color:transparent;
	display:block;
	color:#fff;
	float:left;
	height:$baseline * 1.5;
	line-height:$baseline * 1.5;
	padding:$baseline / 4 0;
	text-align:center;
	transition:background-color .25s ease;
	width:$baseline * 1.5;
	}

.home-hero__nav-count {
	display:block;
	color:#fff;
	float:left;
	height:$baseline * 1.5;
	line-height:$baseline * 1.5;
	padding:0 $baseline / 2 0 0;
	}

.radius-50 {
	border-radius:50%;
	}

.panel-section-title {
	@include delta;
	border-bottom:1px solid rgba(255,255,255, .33);
	color:#fff;
	padding:$baseline  0 $baseline / 2 0;
	@media (min-width: $bp-medium) {
		@include beta;
		padding:$baseline * 2.5 0 $baseline / 2 0;
		}
	}

.panel-section-title--dark {
	border-bottom:1px solid $light-grey-3;
	color:$dark-grey-3;
	}

	
.case-study-teaser {
	display:block;
	margin-bottom:$baseline * 2;
	}

.cs-hero {
	background-position:50% 50%;
	background-size:cover;
	height:90vh;
	position:relative;
	@media (min-width: $bp-medium) {
		height:calc(100vh - #{$baseline * 4});
		}	
	}

.cs-hero__inner {
	bottom:-1px;
	position:absolute;
	width:100%;
	}

.cs-hero__content {
	background-color:rgba(255,255,255, .95);
	background-color:#fff;
	overflow:visible;
	padding:$baseline;
	position:relative;
	@media (min-width: $bp-medium) {
		padding:$baseline $baseline $baseline 0;
		&:before {
			background-color:#fff;
			bottom:0;
			content:'';
			left:- $baseline / 2;
			position:absolute;
			top:0;
			width:$baseline / 2;
			}
		&:after {
			background-color:#fff;
			bottom:0;
			content:'';
			right:- $baseline / 2;
			position:absolute;
			top:0;
			width:$baseline / 2;
			}
		}
	@media (min-width: $bp-large) {
		padding:$baseline $baseline $baseline 0;
		&:before  {
			left:- $baseline;
			width:$baseline;
			}
		&:after  {
			content:none;
			}
		}
	}

 .panel-toggle-trigger {
    border-bottom:1px dotted rgba($light-grey-2, .8);
    display:block;
    padding:$baseline 0;
    transition:color, background-color .25s ease;
    .icon {
        color:$primary-color;
        }
    &:hover {
        text-decoration:none;
        }
    .icon-minus {
        display:none;
        }
    }



.panel-toggle {
    max-height:0;
    overflow:hidden;
    }   

[class*="on--toggle"] {
    [aria-expanded="true"] + .panel-toggle {
        max-height:2000px;
        padding:$baseline;
        }
    [aria-expanded="true"].panel-toggle-trigger .icon-minus {
        display:inline-block;
        }
     [aria-expanded="true"].panel-toggle-trigger .icon-plus {
        display:none;
        }   
    }

.panel-map {
	height:50vh;
	position:relative;
	&:after {
		background-color: transparent;
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		}	
	}

.panel-map--split {
		background: $light-grey-3;
	}

.panel-map__address {
	@media(min-width: $bp-large) {
		min-height: 155px;
	}
}

.js-toggle .icon {
	transform: rotate(0);
	transition: transform .5s ease;
	}


.on--filters {
	.panel-filters {
		max-height:500px;
		opacity:1;
		}
	.js-toggle .icon {
		display:inline-block;
		transform: rotate(180deg);
		}
	}


.animating--filters {
	.panel-filters {
		max-height:0;
		opacity:1;
		}
	.js-toggle .icon {
		display:inline-block;
		transform: rotate(0);
		}
	}

.panel-filters {
	@include clearfix;
	max-height:0;
	opacity:0;
	overflow:hidden;
	transition:max-height .5s ease, opacity .5s ease;
	}


.filter__link {
	border-bottom:1px dotted $light-grey-3;
	color:$mid-grey-3;
	display:block;
	font-size:.8rem;
	padding:$baseline / 2 2px;
	position:relative;
	transition:background-color .25s ease, color .25s ease;
	&:hover {
		background-color:$light-grey-1;
		color:$dark-grey-3;
		}
	&.filter__link--active {
		color:$dark-grey-3;
		}
	}

.filter__link--active {
	background-color:$light-grey-1;
	color:$dark-grey-3;
	}

.cs-intro-wrap {
	overflow:visible;
	position:relative;
	@media (min-width: $bp-large) {
		padding:$baseline / 2 0 $baseline 0;
		}
	&:after {
		background-color:$light-grey-2;
		bottom:0;
		content:none;
		position:absolute;
		right: - $gutter / 2;
		top:0;
		width:1px;
		}
	}

.cs-hero__title {
	@include alpha;
	display:block;
	margin:0 0 $baseline 0;
	@media (min-width: $bp-medium) {
		@include giga;
		}
	@media (min-width: $bp-xlarge) {
		@include tera;
		}
	}


.cs-intro__desc {
	@include alpha;
	color:$dark-grey-1;
	display:block;
	font-family:$primary-font-light;
	margin:0 0 $baseline 0;
	letter-spacing:0.03rem;
	@media (min-width: $bp-medium) {
		@include mega;
		}
	@media (min-width: $bp-large) {
		padding:0 $baseline 0 0;
		}
	@media (min-width: $bp-xlarge) {
		@include giga;
		padding:0;
		}
	}

.cs-details-wrap {
    @media (min-width: $bp-large) {
		position:relative;
		&:after {
			background-color:$light-grey-2;
			bottom:$baseline / 2;
			content:'';
			position:absolute;
			left: - $gutter / 2;
			top:$baseline / 2;
			width:1px;
			}
		}
	}

.cs-share-wrap {
	@include delta;
	color:$light-grey-3;
	display:none;
    a {
		@include gamma;
		background-color:rgba(0,0,0, .33);
		color:$light-grey-1;
		display:inline-block;
		margin-left:$baseline / 12;
		padding:$baseline / 8;
    	}
	}


.panel-cover {
	background-position: 50% 50%;
	background-size: cover;
	margin-bottom:$baseline;
	height: 400px;
	height: 65vh;
    @media (min-width: $bp-large) {
		height: 500px;
		height: 80vh;
  		margin-bottom:$baseline;
    	}
	}

.panel-cover__title {
  	@include peta;
  	color:#fff;
  	font-weight:300;
	}

.home-case-studies {
	padding:$baseline 0 0 0;
	@media (min-width: $bp-large) {
//		padding:$baseline * 6 0 0 0;
		}
	}

.case-study-teaser {
	overflow:hidden;
	position:relative;
	&:hover {
		.case-study-teaser__img {
			opacity:.8;
			transform: scale(1.025);
			}
		}
	}

.case-study-teaser__img {
	opacity:1;
	transform: scale(1);
	transition: .4s linear, opacity .4s linear;
	}

.home-case-studies .wrap .col:first-of-type {
	.case-study-teaser {
//		top: - $baseline * 5 !important;
		}
	}

.case-study-teaser__title {
	@include gamma;
	display:block;
	font-weight:600;
	margin:$baseline / 2 0 0 0;
	@media (min-width: $bp-large) {
		@include beta;
		}
	}

.case-study-teaser__caption {
	bottom:0;
	left:0;
	position:absolute;
	width:50%;
	@media (min-width: $bp-large) {
		bottom:$baseline * 1.5;
		}
	}
	
.case-study-teaser__label {
	@include alpha;
	background: #fff;
	box-decoration-break: clone;
	box-shadow: $baseline / 2 0 0 #fff, -$baseline / 2 0 0 #fff;
	display:inline;
	line-height:$baseline * 1.6;
//	margin-top: 40%;
//	min-height: 20%;
	padding:$baseline / 3 $baseline / 2;
	}

.case-study-teaser {
	&:hover .case-study-teaser__desc {
		max-height:100px;
		}
	}

.case-study-teaser__desc {
	background-color:#fff;
	display:block;
	max-height:0;
	overflow:hidden;
	transition: max-height .5s ease;
	}

.statement {
	height: 30em;
	padding-left: 10em;
	padding-right: 5em;
	padding-top: 10em;
	width: 100%;
	}


.btn-statement {
	background: #333333 none repeat scroll 0 0;
	color: white;
	font-size: 0.8rem;
	font-weight: 500;
	height: auto;
	opacity: 1;
	padding: 10px 18px;
	position: relative;
	transition: background 0.3s linear 0s;
	width: 180px;
	z-index: 9;
	}

.hatching-grid {
	background-image: url("/content/img/hatching.png");
	background-size: 100% auto;
	height: 340px;
	margin-left: 50%;
	margin-top: -340px;
	max-width: 800px;
	opacity: 0.25;
	transition: all 1s ease-in-out 0s;
	}

.btn-case-study-small {
	background: #333333 none repeat scroll 0 0;
	color: white;
	display:block;
	font-size: 0.8rem;
	font-weight: 500;
	height: auto;
	opacity: 1;
	padding: 10px 18px;
	position: relative;
	transition: background 0.3s linear 0s;
	width: 120px;
	}


.wrap--home-intro {
	overflow:visible; 
	position:relative
	}

.home-intro {   
	min-height: 80vh;
	overflow:visible;
	position:relative;
	z-index:2;
	.vertical-align {
		width:100%;
		}
	@media (min-width: $bp-medium) {
		min-height: 50vh;
		}
	@media (min-width: $bp-xlarge) {
		min-height: 50vh;
		}
	}

.texture-grid {
	background-image: url("/content/img/brand/texture-cross.svg");
	background-size:15%;
	opacity: .1;
	position:absolute;
	right:0;
	top:25%;
	transition: all 1s ease-in-out 0s;
	width:75%;
	@media (min-width: $bp-medium) {
		background-size:10%;
		}
	@media (min-width: $bp-large) {
		background-size:8%;
		width:50%;
		}
	@media (min-width: $bp-xlarge) {
		background-size:5%;
		}
	}

.texture-grid__inner {
	@include maintain-ratio(1 1);
	}


.news-teaser {
	display:block;
	margin-bottom:$baseline;
	padding:0 0 $baseline 0;
	position:relative;
	&:hover {
		.news-teaser__img {
			opacity:.8;
			}
		}
	}

.news-teaser__img {
	opacity:1;
	transition:opacity .25s ease;
	}

.news-teaser__title {
	@include beta;
	display:block;
	font-weight:600;
	margin:$baseline / 2 0;
	min-height:30px;
	overflow:hidden;
}

.news-teaser__desc {
	@include gamma; 
	color:$mid-grey-3;
	display:block;
	margin-bottom:$baseline/1.4;
	min-height:30px;
	overflow:hidden;

	@media(min-width:$bp-medium) { 
		min-height:100px; 
	} 
	@media(min-width:$bp-medium-large) { 
		min-height:110px; 
	}
	@media(min-width:$bp-large) { 
		min-height:105px; 
	}
	@media(min-width:$bp-large-xlarge) { 
		min-height:75px; 
	}
	@media(min-width:$bp-xlarge) { 
		min-height:60px; 
	}
	}

.news-teaser__date {
	@include delta;
	color:$mid-grey-3;
	display:block;
	letter-spacing: .1rem;
	margin-bottom:$baseline/4;
	position:relative;
	left:0;
	}

.news-article__title {
	@include giga;
	display:block;
	margin-bottom:$baseline;
	@media (min-width: $bp-medium) {
		@include giga;
		}
	@media (min-width: $bp-xlarge) {
		@include tera;
		margin-bottom:$baseline * 2;
		}
	}


.search-result__item {
	border-bottom:1px dotted $light-grey-2;
	display:block;
	margin:0;
	}

.search-result__link {
	display:block;
	margin:0;
	padding:$baseline / 2 $baseline / 8;
	transition:background-color .25s ease;
	&:hover {
		background-color:$light-grey-1;
		}
	@media (min-width: $bp-medium) {
		padding:$baseline $baseline / 6;
		}
	}

.search-result__title {
	@include beta;
	display:block;
	@media (min-width: $bp-medium) {
		@include mega;
		}
	}

.search-result__desc {
	@include gamma;
	color:$mid-grey-3;
	display:block;
	}


 .panel-three-images {
	overflow:hidden;
	@media (min-width: $bp-large) {
		position:relative;
		&:before {
			background-color:#fff;
			bottom:0;
			content:'';
			height:$baseline * 2;
			left:0;
			position:absolute;
			right:0;
			width:100%;
			}
		}
 	}



//About Us hero image ---------------------------------------------------

.about-hero {
	/*background-image:url("/content/img/temp/team-header-1600x900.jpg"); */
	background-position:center 60%;
	background-size:cover;
	display:block; 
	height:calc(100vh - 6rem);
	min-height: 400px; 
	width:100%; 
	@media(min-width: $bp-medium) {
		min-height: 500px; 
	}
	@media(min-width: $bp-large) {
		min-height: 600px; 
	}
	@media (min-width:$bp-xlarge) {
		background-position:center 70%; 
	}
	@media (min-width:$bp-xxlarge) {
		background-position:center 75%; 
	}
	@media (max-width:$bp-small-medium) {
		background-position:center bottom;
		background-repeat:no-repeat;
		background-size:contain;
		height:auto;
		padding-bottom:100px;
		img {
			width:100%;
		}
	}
	.vertical-align {
		top:100%;
		@media (max-width:$bp-small-medium) {
			top:50%;
		}
	}

}

.about-hero__text-container {
	//display:block;
	height: 50vh;
	//position:relative;
	@media (min-width:$bp-small-medium) {
		height: 25vh;
	}
	@media (min-width:$bp-medium) {
		height: 30vh;
	}
	@media (min-width:$bp-xlarge) {
		height: 40vh;
	}
}

.bg-white-hero-text {
	display:inline;
	background:white;
	color:$off-black;
	padding-bottom:0.3rem;
	padding-top:0.1rem;
	padding-left:10px;
	padding-right:20px;
    box-decoration-break: clone;
	@media (max-width:$bp-small-medium) {
		background:none;
		padding-bottom:0.1rem;
		padding-left:0;
		padding-right:0;
	}
}






