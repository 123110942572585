/*
  Modal Overlay
*/
.modal-overlay {
  background:rgba(255, 255, 255, .98);
  display: flex;  
  height: 100%;
  left: 0;
  overflow:visible;
  padding:0;
  position: fixed;
  top: $baseline * 2;
  transition: opacity .2s;
  width: 100%;
  z-index: 5;
  @media (min-width: $bp-large) {
      left: $site-header-width !important;
      top: $baseline * 3;
      width: calc(100% - #{$site-header-width} ) !important;
      }
  }

.on--nav-secondary .modal-overlay {
  @media (min-width: $bp-large) {
    top: $baseline * 5;
    }
  }

.modal-overlay[aria-hidden="true"] {
  opacity: 0;
  visibility: hidden;
  }

.modal-overlay[aria-hidden="false"] {
  opacity: 1;
  visibility: visible;
  z-index: 4;
  }

/*
  Modal Content Area
*/
.modal-content {
  background: transparent;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top:$baseline * 2;
  position:relative;
  @media (min-width: $bp-medium) {
    padding-top:$baseline * 4;
    }
  @media (min-width: 70em) {
    padding-top:$baseline * 6;
    }
  }

.modal-overlay[aria-hidden="false"] .modal-content {

  }

/*
  Close Modal Button
*/
.modal-close {
  @include mega;
  background-color:transparent;
  color:$off-black;
  cursor: pointer;
  display:block;
  padding: 0;
  position: absolute;
  right: $baseline / 2;
  top:$baseline / 2;
  z-index:5;

  @media (min-width: $bp-medium) {
    right: $baseline * 2;
    top: $baseline * 2;
    }
    &:hover, 
    &:focus {
      color: $off-black;
      }
      
  }

