// Colour
//
.primary-color {
	color:$primary-color;
	}
.bg-primary-color {
	background-color:$primary-color;
	}
	
.secondary-color {
	color:$secondary-color;
	}
.bg-secondary-color {
	background-color:$secondary-color;
	}

 .bg-primary-color-dark--10 {
	background-color:darken($primary-color, 10%); 
	}
	
 .bg-primary-color-dark--20 {
	background-color:darken($primary-color, 20%);
	}

// = Grayscale
//-----------------------------------------------------------------------------//
.light-grey-1 {
	color:$light-grey-1;
	}
.light-grey-2 {
	color:$light-grey-2;
	}
.light-grey-3 {
	color:$light-grey-3;
	}
.bg-light-grey-1 {
	background-color:$light-grey-1;
	}
.bg-light-grey-2 {
	background-color:$light-grey-2;
	}
.bg-light-grey-3 {
	background-color:$light-grey-3;
	}
.mid-grey-1 {
	color:$mid-grey-1;
	}
.mid-grey-2 {
	color:$mid-grey-2;
	}
.mid-grey-3 {
	color:$mid-grey-3;
	}
.bg-mid-grey-1 {
	background-color:$mid-grey-1;
	}
.bg-mid-grey-2 {
	background-color:$mid-grey-2;
	}
.bg-mid-grey-3 {
	background-color:$mid-grey-3;
	}
.dark-grey-1 {
	color:$dark-grey-1;
	}
.dark-grey-2 {
	color:$dark-grey-2;
	}
.dark-grey-3 {
	color:$dark-grey-3;
	}
.bg-dark-grey-1 {
	background-color:$dark-grey-1;
	}
.bg-dark-grey-2 {
	background-color:$dark-grey-2;
	}
.bg-dark-grey-3 {
	background-color:$dark-grey-3;
	}
.white {
	color:#fff;
	}
.bg-white {
	background-color:#fff;
	}
.off-black {
	color:$off-black;
	}
.bg-off-black {
	background:$off-black;
	}

// = Opacity
//-----------------------------------------------------------------------------//

.opacity--1 {
	opacity:(.8)
	}
.opacity--80 {
	opacity:(.8)
	}
.opacity--80 {
	opacity:(.8)
	}
.opacity--40 {
	opacity:(.4)
	}
.opacity--20 {
	opacity:(.2)
	}


// = Borders
//-----------------------------------------------------------------------------//

.border-top {
	border-top:1px solid $mid-grey-2;
	}
	
.border-bottom {
	border-bottom:1px solid $mid-grey-2;
	}

.border--primary-color {
	border-color:$primary-color;
	}

.border--light {
	border-color:#fff;
	}


// = Overline
//-----------------------------------------------------------------------------//
.overline {
	background-color:$mid-grey-2;
	display:block;
	height:1px;
	text-align:left;
	width:$baseline * 2;
	}

.overline--half {
	width:$baseline / 2;
	}

.overline--4 {
	width:$baseline * 4;
	}
	
.overline--centered {
	margin-left:auto;
	margin-right:auto;
	}

.overline--angle-up {
//	margin-left: - $baseline / 2;
//	min-width:$baseline * 3;
//	transform: rotate(-15deg);
	}

.overline--angle-down {
//	margin-right:-  $baseline;
//	min-width:$baseline * 3;
//	transform: rotate(15deg);
	}




	