.tabs-accordion__nav {
    display:none;
    @media (min-width: $bp-medium) {
        @include clearfix();
        border-bottom: solid 1px $light-grey-2;
        clear:both;
        display:block;
        height:$baseline * 2;
        margin: 0;
        padding: 0;
        }
    @media (min-width: $bp-large) {
        margin-bottom: $baseline;
        }
    }

.tabs-accordion__nav-link {
    @media (min-width: $bp-medium) {
        color:inherit;
        display:inline-block;
        float:none;
        height:$baseline * 2;
        line-height:$baseline * 2;
        margin:0 $baseline / 2 $baseline / 2 $baseline / 2;
        position:relative;
        transition: all 0.2s ease 0s;
        width:auto;
        &:hover,
        &:focus {
            color: $primary-color;
            text-decoration:none;
            }
         &.active {
            background:transparent;
            color:inherit;
            }

        &.active:after {
            left: 0;
            right:0;
            transition: all 0;
            width:100%;
            }

         &:after {
            background-color: $primary-color;
            bottom: 0;
            content: "";
            display: block;
            height:$baseline / 12;
            left: 50%;
            position: absolute;
            right:50%;
            transition: all 0.2s;
            width:0;
            }
        &:hover:after {
            background-color: $primary-color;
            left: 0;
            right:0;
            transition: all 0;
            width: 100%;
            }
        }
    }

.tabs-accordion__section {
    background:transparent;
    }

.tabs-accordion__section-heading {
    background-color: transparent;
    border-top:1px dotted $mid-grey-3;
    cursor: pointer;
    display:block;
    font-family:$primary-font;
    font-size:1rem;
    margin: 0;
    padding:$baseline * .75 0 $baseline * .75 $baseline * 1.5;
    position:relative;

    &:hover {
        color:$primary-color;
        }

    &:after {
        color:$primary-color;
        content: '\E802';
        display: block;
        font-family:"fontello";
        font-size:19px;
        left:$baseline  / 2;
        position:absolute;
        top:$baseline * .75;
        }

    @media (min-width: $bp-medium) {
        display:none;
        }

    }

.active .tabs-accordion__section-heading:after  {
    content: '\E803';
    }

.tabs-accordion__section-inner {
    @include clearfix;
    max-height:0;
    overflow:hidden;
    clear:both;
    }

.tabs-accordion__section.active .tabs-accordion__section-inner {
    max-height:5000px;
    padding:$baseline 0;
    @media (min-width: $bp-medium) {
        padding:$baseline  0;
        }
    }




.tabs-accordion-vertical {
    @include clearfix;
    background-color:#fff;
    @media (min-width: $bp-medium) {
        min-height:600px;
        position:relative;
        }

    .tabs-accordion__nav {
        @media (min-width: $bp-medium) {
            background-color:#fff;
            border-bottom: none;
            float: left;
            height: auto;
            margin: 0;
            width: 25%;
            }
        }
    .tabs-accordion__section {
        @media (min-width: $bp-medium) {
            background-color:#fff;
            float:right;
            width:75%;
            }
        }
    .tabs-accordion__nav-link {
        @media (min-width: $bp-medium) {
            display:block;
            height:auto;
            line-height:auto;
            &:after {
                content:none;
                }
            }
        }
    .tabs-accordion__section.active .tabs-accordion__section-inner {
        @media (min-width: $bp-medium) {
            padding:$baseline;
            }
        }
    }

    
