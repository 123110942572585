/* Footer
--------------------------------------------------------------*/
$site-footer-height: $baseline * 28;

.content--main {
	@media (min-width:$bp-medium){
		background-color:#fff;
		margin-bottom:$site-footer-height;
		min-height:100vh;
		position:relative;
		z-index:2;	
		}
}

.footer-logo {
	max-width:100px;
}

.footer-social-icon:hover {
	opacity:0.7;
	transition:opacity ease-out 0.3s;
}

.site-footer {
	@include gamma;
	background-color:$dark-grey-3;
	color:$light-grey-2;
	line-height:1.5;
	padding:0 0 $baseline * 2 0;

	@media (min-width:$bp-medium) {
		bottom:0;
		height:$site-footer-height;
		position:fixed;
		width:100%;
		z-index:1			
		}

	@media (min-width:$bp-large) {
		width:calc(100% - #{$site-header-width});		
		}

	a {
		color:$light-grey-2;
		}

	.email-link {
		display:block;
		&:hover {
			color:$light-grey-1;
			text-decoration:underline;
			}
	}
	
		&:hover {
			color:$light-grey-1;
			text-decoration:underline;
			}

	* {
//		outline:1px solid red;
		}
	}

$btn-newsletter-width:140px;

.form-control.form-control--newsletter {
	display:inline-block;
	width:calc(95% - #{$btn-newsletter-width});
	}

.btn--newsletter {
	display:inline-block;
	width:$btn-newsletter-width;
	}