
.img-wrap {
    display:block;
    overflow:hidden;
    position:relative;
    img {
        height:100%;
        left:0;
        position:absolute;
        top:0;
        width:100%;
        }
    }

.img-wrap--1-1 {
    @include maintain-ratio(1 1);
    }

.img-wrap--5-3 {
    @include maintain-ratio(5 3);
    }

.img-wrap--16-9 {
    @include maintain-ratio(16 9);
    }

.img-wrap--1471-827 {
    @include maintain-ratio(1471 827);
    }
