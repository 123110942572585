/* Header
-------------------------------------------------------------- */

* {
//	outline:1px solid red;
	}

$site-header-width: $baseline * 8;  

.site-header {
    overflow:visible;
    padding:$baseline / 2 0;
    position:relative;
    @media (min-width: $bp-medium) {
        padding-left: $gutter;
        padding-right: $gutter;
        }
    @media screen and (min-width: $bp-large) {
        background-color:#fff;  
        border-right:1px solid $light-grey-2;
        height:100%;
        left:0;
        padding:$baseline * 2.38 $baseline;
        position:fixed;
        text-align:center;
        top:0;
        width:$site-header-width;
        z-index:5;
    	}
	}

.content,
.site-footer {
    @media screen and (min-width: $bp-large) {
        margin-left:$site-header-width;
    	}
	}

.site-logo {
    display:block; 
    opacity:1;
    transition:opacity .3s ease;
    &:hover {
        opacity:.8;
        }
    @media (max-width: 59.995em) {
        margin: $baseline / 6 $gutter / 2;
        }
    }

.site-logo__img {
    height:25px;
    width:150px;
    @media (min-width: $bp-medium) {
        height:34px;
        width:200px;
        }
    }


.btn-search {
    @include giga;
    color:$dark-grey-3;

    &:hover {
        color:$dark-grey-3;
        cursor:pointer;
        }

    @media (max-width: 59.995em) {
//        outline:1px solid red;
        position:absolute;
        right:$baseline * 3;
        top:$baseline / 2;
        }

    @media (min-width: $bp-large) {
        @include mega;
        color:$mid-grey-3;
        margin: $baseline 0;   
        position:fixed;
        left:$baseline * 3.25;
        top:85vh; 
        }

    }



