// placeholder
// usage: @include placeholder(#000);
@mixin placeholder($color) {
    ::-webkit-input-placeholder {color:$color;}
    ::-moz-placeholder {color:$color;} /* FF 19+ */
    :-moz-placeholder {color:$color;} /* FF 18- */
    :-ms-input-placeholder {color:$color;}
}

//keyframes
//usage @include keyframes(slide-down) { 0% { opacity: 1; }  90% { opacity: 0; }}
@mixin keyframes($animation-name) {
  @-webkit-keyframes $animation-name {
    @content;
  }
  @-moz-keyframes $animation-name {
    @content;
  }  
  @-ms-keyframes $animation-name {
    @content;
  }
  @-o-keyframes $animation-name {
    @content;
  }  
  @keyframes $animation-name {
    @content;
  }
}

//animation
//usage @include animation('slide-down 5s 3');
@mixin animation($str) {
    backface-visibility: hidden; 
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};      
}

//columnise, css columns
//usage @include columnise(2, 20px);
@mixin columnise($num, $gap) {
    columns: $num;
    column-gap: $gap;
}

// clearfix
// usage: @include clearfix;
@mixin clearfix {
    overflow: none;
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}
.clearfix {
    @include clearfix;
    }

//hidden
// usage: @include hidden;

@mixin visuallyhidden {
    position: absolute;
    visibility: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0;
}
.visuallyhidden {
    @include visuallyhidden
    }

//visible
// usage: @include visible;
@mixin visible {
    position: static;
    visibility: visible;
    width: auto;
    height: auto;
    padding: 0;
    margin: auto;
    overflow:inherit;
    clip:auto;
}


// image replacement
// usage: @include ir;
@mixin ir {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
}

//icon mixins
@mixin icon-arrow-down {
    position:relative;
        &:after {
            content:'';
            position:absolute;
            right:$gutter*2;
            top:50%;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: currentColor transparent transparent transparent;
            margin:-.15rem 0 0 10px;
        }
}

@mixin icon-arrow-up {
    position:relative;
        &:after {
            content:'';
            position:absolute;
            right:$gutter*2;
            top:50%;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: currentColor transparent transparent transparent;
            transform:rotate(-180deg);
        }
}

@mixin icon-arrow-right {
    position:relative;
        &:after {
            content:'';
            position:absolute;
            right:$gutter*2;
            top:50%;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: currentColor transparent transparent transparent;
            transform:rotate(-90deg);
            margin-top:-.25rem;
        }
}
@mixin icon-arrow-left {
    position:relative;
        &:after {
            content:'';
            position:absolute;
            right:$gutter;
            top:50%;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: currentColor transparent transparent transparent;
            transform:rotate(90deg);
            margin-top:-.25rem;
        }
}

@mixin icon-arrow-down--mobile {
    @include icon-arrow-down;
    @media (min-width:$bp-medium) {
        cursor:auto;
    }
        &:after {
             @media (min-width:$bp-medium) {
                display:none;
            }
        }
}



// = Vertical align
//-----------------------------------------------------------------------------//

@mixin vertical-align($position: absolute) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
    }



// = Absolute inner
//-----------------------------------------------------------------------------//
@mixin absolute-inner {
    bottom:0;
    left:0;
    position:absolute;
    right:0;
    top:0;
}

// = Maintain ratio
//-----------------------------------------------------------------------------//
// https://gist.github.com/brianmcallister/2932463
// Maintain ratio mixin. Great for responsive grids, or videos.
// https://gist.github.com/brianmcallister/2932463
//
// $ratio - Ratio the element needs to maintain.
//
// Examples
//
//   // A 16:9 ratio would look like this:
//   .element {
//     @include maintain-ratio(16 9);
//   }
@mixin maintain-ratio($ratio: 1 1) {
  @if length($ratio) < 2 or length($ratio) > 2 {
    @warn "$ratio must be a list with two values.";
  }

  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));

  width: $width;
  height: 0;
  padding-bottom: $height;
}