/* Buttons
-------------------------------------------------------------- */
//skip to content
.skip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
        .btn-skip {
            height: 1px;
            width: 1px;
            position: absolute;
            overflow: hidden;
            top: -10px;
            left: 0;
            &:focus {
                position: fixed;
                height: auto;
                width: auto;
                margin:0 auto;
                display: block;
                left: 0;
                right: 0;
                top:5px;
                text-align: center;
            }
        }
    }




.btn {
    background-color:$light-grey-1;
    border:none;
    color: $dark-grey-2;
    cursor: pointer;
    display: inline-block;
    font-weight:normal;
    height:44px;
    line-height:44px;
    outline: none;
    padding: 0 $baseline * .66;
    position: relative;
    text-decoration: none;

    @media (min-width: $bp-large) {
        transition: background-color 0.3s ease, color .3s ease;
        }

    &:hover,
    &:focus {
        background-color:darken($light-grey-2, 5%);
        color: $dark-grey-3;
        text-decoration:none;
        }
    &:visited {
        color: $dark-grey-2;
        }

    &.active,
    &:active {
        background-color:darken($light-grey-1, 20%);
        color: $dark-grey-3;
        }
    }


.btn--icon {
    padding-right:$baseline / 6;
    }

.btn--large {
    height:64px;
    font-size:32px;
    line-height:64px;
    }

.btn--small {
    height:36px;
    font-size:13px;
    line-height:36px;
    padding: 0 $baseline * .66;
    }

.btn--mini {
    height:22px;
    font-size:11px;
    line-height:22px;
    padding: 0 $baseline / 3;
    &.btn--icon {
        padding-right:33px;
        }
    .icon {
        font-size:11px;
        line-height:22px;
        padding-left:5px;
        padding-right:5px;
        }
}


// Block button
// -------------------------

.btn--block {
    display:block;
    width:100%;
    }

// Vertically space out multiple block buttons
.btn--block + .btn--block {
    margin-top: $baseline / 2;
    }


.btn--primary {
    background-color:$dark-grey-3;
    border:none;
    color:#fff;
    &:hover,
    &:focus,
    &.active,
    &:active {
        background-color:$dark-grey-1;
        color:#fff;
        }
    &:visited {
        color:#fff;
        }
    }

.btn--dark {
    color:#fff;
    background-color:$dark-grey-3;
    &:hover {
        color:$dark-grey-3;
        background-color:$dark-grey-1;
        }
    &:visited {
        color:#fff;
        }
    }

.btn--outline {
    background-color:transparent;
    border:1px solid $mid-grey-3;
    color: $mid-grey-3;
    &:hover,
    &:focus,
    &.active,
    &:active {
        background-color:transparent;
        border:1px solid $dark-grey-3;
        color:$dark-grey-3;
        }
    &:visited {
        color:$mid-grey-3;
        }
    }

.btn--outline-primary {
    background-color:transparent;
    border:1px solid $primary-color;
    border-radius:2px;
    color:$primary-color;
    &:hover,
    &:focus,
    &.active,
    &:active {
        background-color:transparent;
        border-color:#fff;
        color:#fff;
        }
    &:visited {
        color:$primary-color;
        }
    }

.cs-cta {
    overflow:hidden !important;
    }


.btn--cta {
    position:relative;
    overflow:visible;
    &:before,
    &:after {
        background:$light-grey-3;
        content:'';
        height:1px;
        position:absolute;
        top:50%;
        width:800%;
        }
    &:before {
        margin-right:calc(100% + 30px;
        right:0;
        }
    &:after {
        left:0;
        margin-left:calc(100% + 30px;
        }
    }