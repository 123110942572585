    @font-face{
        font-family:"Neue Haas Grotesk W01_n4";
        src:url("/content/fonts/d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix") format("eot")
        }

    @font-face {
        font-family:"Neue Haas Grotesk W01";
        src:url("/content/fonts/d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix");
        src:url("/content/fonts/d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix") format("eot"),
        url("/content/fonts/a14594bf-73de-4b5f-9792-9566994a021d.woff2") format("woff2"),
        url("/content/fonts/bb4a10bb-155d-4c1a-a813-c65e10fac36c.woff") format("woff"),
        url("/content/fonts/53812a68-b352-4951-b19c-fe964db7ffe2.ttf") format("truetype"),
        url("/content/fonts/389e288e-637b-44b2-9b5a-4ecec3ae8e2c.svg#389e288e-637b-44b2-9b5a-4ecec3ae8e2c") format("svg");
        font-weight: 400;
        font-style: normal;
        }

    @font-face {
        font-family:"Neue Haas Grotesk W01_n6";
        src:url("/content/fonts/fca16206-1413-42b5-b3dd-ce6499d2bd3f.eot?#iefix") format("eot")
        }
    @font-face{
        font-family:"Neue Haas Grotesk W01";
        src:url("/content/fonts/fca16206-1413-42b5-b3dd-ce6499d2bd3f.eot?#iefix");
        src:url("/content/fonts/fca16206-1413-42b5-b3dd-ce6499d2bd3f.eot?#iefix") format("eot"),
        url("/content/fonts/34ae0cd2-c49c-4df4-8270-fcda21c1b715.woff2") format("woff2"),
        url("/content/fonts/9e666926-4bc9-4013-849e-dffa25a41dbd.woff") format("woff"),
        url("/content/fonts/37e13425-7daf-407c-ba41-43ebd7d30855.ttf") format("truetype"),
        url("/content/fonts/9cd38052-e8fa-4a89-bfee-01d1675c9aa1.svg#9cd38052-e8fa-4a89-bfee-01d1675c9aa1") format("svg");
        font-weight: 600;
        font-style: normal;
        }

    @font-face{
        font-family:"Neue Haas Grotesk W01_n7";
        src:url("/content/fonts/8d290bc2-1f22-40ea-be12-7000a5406aff.eot?#iefix") format("eot")
        }

    @font-face  {
        font-family:"Neue Haas Grotesk W01";
        src:url("/content/fonts/8d290bc2-1f22-40ea-be12-7000a5406aff.eot?#iefix");
        src:url("/content/fonts/8d290bc2-1f22-40ea-be12-7000a5406aff.eot?#iefix") format("eot"),
        url("/content/fonts/d13fb250-6b64-4d97-85df-51fc6625a891.woff2") format("woff2"),
        url("/content/fonts/60fa2ce6-c35e-4203-9bbf-25dd128daec5.woff") format("woff"),
        url("/content/fonts/dda121ff-e230-440f-83fb-40aefbd6e09a.ttf") format("truetype"),
        url("/content/fonts/c98782d3-8599-4314-b717-118a629a3aa4.svg#c98782d3-8599-4314-b717-118a629a3aa4") format("svg");
        font-weight: 700;
        font-style: normal;
        }

    @font-face {
        font-family:"Neue Haas Grotesk W01 Disp_n4";
        src:url("/content/fonts/d5211394-289b-4a20-a4c5-69e82b7f1cdc.eot?#iefix") format("eot")
        }

    @font-face {
        font-family:"Neue Haas Grotesk W01 Disp";
        src:url("/content/fonts/d5211394-289b-4a20-a4c5-69e82b7f1cdc.eot?#iefix");
        src:url("/content/fonts/d5211394-289b-4a20-a4c5-69e82b7f1cdc.eot?#iefix") format("eot"),
        url("/content/fonts/fcd607c0-74ab-4cb5-90c3-db118b1ad287.woff2") format("woff2"),
        url("/content/fonts/5f644ac3-aa3c-43a7-ae6e-79075f4766df.woff") format("woff"),
        url("/content/fonts/88228648-8c26-4d51-a51b-f92564a92392.ttf") format("truetype"),
        url("/content/fonts/24bd485c-6511-4e93-91d9-4764d66fc2df.svg#24bd485c-6511-4e93-91d9-4764d66fc2df") format("svg");
        font-weight: 400;
        font-style: normal;
        }

    @font-face{
        font-family:"Neue Haas Grotesk W01";
        src:url("/content/fonts/baa1ea73-44ac-4bb5-a6af-b7fc486d335f.eot?#iefix");
        src:url("/content/fonts/baa1ea73-44ac-4bb5-a6af-b7fc486d335f.eot?#iefix") format("eot"),
        url("/content/fonts/dc9df9ed-36b9-4522-8e57-1a899ed2c224.woff2") format("woff2"),
        url("/content/fonts/ff571a3a-fb16-42b1-a691-23d8955aa35e.woff") format("woff"),
        url("/content/fonts/4e756bdf-4269-4158-aad4-70a09c5eed5c.ttf") format("truetype"),
        url("/content/fonts/91554ebe-051b-4fa7-bf6e-ac7ed5d0d107.svg#91554ebe-051b-4fa7-bf6e-ac7ed5d0d107") format("svg");
        font-weight: 400;
        font-style: italic;
        }

// = base
//-----------------------------------------------------------------------------//
body {
    color:#333;
    font-family:$base-font-family;
    font-feature-settings: "liga", "kern";
//    font-size:$font-base;
//    line-height:$font-base-line-height;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
	}

.primary-font-light {
    font-family:$primary-font-light;
    }

.primary-font {
    font-family:$primary-font;
	}

.primary-font-medium {
    font-family:$primary-font;
    font-weight:700;
    }

.primary-font-bold {
    font-family:$primary-font-bold;
    font-weight:700;
    }

// = Styling
//-----------------------------------------------------------------------------//

.letterspace {
	letter-spacing:.1rem;
	}

.uppercase {
	text-transform:uppercase;
	}

.light {
    font-family:"Neue Haas Grotesk W01 Disp";
    }

.bold {
    font-weight:$bold;
    }

.black {
    font-weight:$black;
    }

// = Modular scale
//-----------------------------------------------------------------------------//
@mixin exa {
    font-size:32px;
    line-height:1.05;
    @media (min-width: $bp-medium) {
        font-size:4rem;
        }
    @media (min-width: $bp-large) {
        font-size:5rem;
        }
    @media (min-width: $bp-xlarge) {
        font-size:7rem;
        }
    }

.exa {
    @include exa
    }

@mixin peta {
    font-size:32px;
    line-height:1.05;
    @media (min-width: $bp-medium) {
        font-size:3rem;
        }
    @media (min-width: $bp-large) {
        font-size:3.75rem;
        }
    }

.peta {
    @include peta;
    }

@mixin tera {
    font-size:1.75rem;
    line-height: 1.05;
    @media (min-width: $bp-medium) {
        font-size:2.5rem;
        line-height:1.125;
        }
    @media (min-width: $bp-large) {
        font-size:2.75rem;
        line-height:1.05
        }
    }

.tera {
    @include tera
    }

@mixin giga {
    font-size:1.5rem;
    line-height:1.15;
    p {
        margin-bottom:1.5rem;
        }
    @media (min-width: $bp-medium) {
        font-size:2rem;
        line-height:1.2;
        p {
            margin-bottom:2.375rem;
            }
        }
    @media (min-width: $bp-large) {
        font-size:2.25rem;
        }
        p {
            margin-bottom:2.5rem;
            }
    }

.giga {
    @include giga;
    }

@mixin mega { 
    font-size: 1.313rem;
    line-height: 1.3;
    p {
        display:block;
        margin-bottom: 1.5rem;
        }
    @media (min-width: $bp-medium) {
        font-size:1.5rem;
        line-height: 1.3;
        }
    }

.mega {
    @include mega
    }

@mixin alpha {
    font-size: 1.125rem;
    line-height: 1.25;
    p {
        display:block;
        margin-bottom: 1.125rem;
        }
    }

.alpha {
    @include alpha
    }

@mixin beta {
    font-size:1rem;
    line-height:1.25;
    p {
        margin-bottom:1rem
        }
    }

 .beta {
     @include beta
    }

@mixin gamma {
    font-size:0.875rem;
    line-height:1.4;
    }

.gamma {
    @include gamma
    }

@mixin delta {
    font-size:0.75rem;
    line-height:1.4;
    }

.delta {
    @include delta
    }

@mixin epsilon {
    font-size:0.625rem;
    line-height:1.4;
    }

.epsilon {
    @include epsilon;
    }


// = Editor content
//-----------------------------------------------------------------------------//
h1, h2, h3, h4, h5 {
    font-weight:inherit;
    }
.editor {  
    position:relative;
    h1, h2, h3, h4, h5 {
        color:$dark-grey-3;
        display:block;
        font-size:1.15rem;
        line-height:1.2;
        margin-bottom:18px;
        }
    h1 {
        }
    h2 {
        font-weight:700;
        }
    h3 {
        font-weight:700;
        }
    h4 {
        font-size:18px;
        margin-bottom:2px;
        }
    p, ol, ul {
        color:$dark-grey-2;
        display:block;
//        font-family:$primary-font-light;
        font-size:1.15rem;
        display:block;        
        line-height:1.6;
        margin:0 0 1rem 0;
        }

    .small {
        font-size:13px;
        }
    ol, ul {
        margin:0 0 $baseline * 1.5 $baseline * 1.5;
        }
    ol {
        list-style-type:decimal;
        }
    ul {
        list-style-type:disc;
        }
    li {
        margin-bottom:$baseline / 4;
        }
     a {
        background: linear-gradient(#fff, #fff) no-repeat scroll 0 95% / 0.05em 1px, 
        linear-gradient(#fff, #fff) no-repeat scroll 100% 95% / 0.05em 1px, rgba(0, 0, 0, 0) linear-gradient($dark-grey-2, $dark-grey-2) repeat-x scroll 0 95% / 1px 1px;
        text-shadow: 0.03em 0 #fff, -0.03em 0 #fff, 0 0.03em #fff, 0 -0.03em #fff, 0.06em 0 #fff, -0.06em 0 #fff, 0.09em 0 #fff, -0.09em 0 #fff, 0.12em 0 #fff, -0.12em 0 #fff, 0.15em 0 #fff, -0.15em 0 #fff;
        &:hover {
            background:none;
            text-shadow:none;
            }
        }
        a {
            color:$primary-color;
            &:hover {
                color: shade($primary-color,20%);
                text-decoration:none;
                }

            }
        blockquote {
            @include mega;
            display:block;
            font-family:$primary-font-light;
            font-weight:300;
            line-height:1;
            margin-bottom:$baseline;
            padding:$baseline * 2.5 0 $baseline * 1.5;
            position:relative;

            &:before {
                display: block;
                color:$primary-color;
                content: "\201C";
                //font-family:georgia, serif;
                font-size: 72px;
                left:0;
                position: absolute;
                text-align:center;
                top:0;
                @media (min-width: $bp-medium)  {
                    font-size: 120px;
                    }
                }
            &:after {
                bottom:0;
                display: block;
                background-color:$primary-color;
                content:"";
                height:4px;
                left:0;
                position: absolute;
                width:40px;
                }

            @media (min-width: $bp-large) {
//                left:calc(-33% - 24px;
//                position:absolute;
//                width:33%;
                }
            cite {
                display:block;
                font-size:14px;
                }
            }
        blockquote.blockquote--right {
            @media (min-width: $bp-large) {
                left:auto;
                position:absolute;
                right:calc(-33% - 24px;
                width:33%;
                }
            }

        figure {
            margin-bottom:$baseline;
            }

        pre {
            margin-bottom:$baseline;
        }
        img {
            height:auto;
        }
    }

.editor--article {
    position:relative;
    p, ol, ul, blockquote {
        @media (min-width:$bp-medium) {
            max-width:60%;            
            }
        }
    p:first-child::first-letter {
        float: left;
        font-size: 7rem;
        font-weight:100;
        line-height: 1.1em;
        margin: -0.15rem .5rem -.5rem 0;
        }
    figure {
        @media (min-width:$bp-medium) {
            margin-left: -20%;            
            }
        }
    }



blockquote {
//    font-family:secondary-font;
    cite {
//        display:block;
//        font-family:secondary-font-italic;
//        font-style:normal;
//        margin-top:$baseline * 2;
        }
    }

a {
    color:$primary-color;
    color:$dark-grey-2;
    }

a:hover {
    color: shade($primary-color,20%);
    }

.run-in {
    color:$dark-grey-1;
    font-family:$primary-font-light;
    font-size:1.5rem;
    line-height:1.6;
    @media (min-width:$bp-medium) {
        max-width:60%;            
        }
    }



// = Text Align
//-----------------------------------------------------------------------------//

.text--center { 
    text-align:center;
    }

.text--left { 
    text-align:left;
    }

.text--right { 
    text-align:right;
    }

.text-right--medium { 
    @media (min-width:$bp-medium) {
        text-align:right;         
        }
}

