/* Peppermint required styles + default appearance styles */

.peppermint.peppermint-active {
    position: relative;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    }

.peppermint.peppermint-active .peppermint-slides {
    position: relative;
    overflow: hidden; /* clearfix */
    touch-action: pan-y;
    }

.peppermint.peppermint-active .peppermint-slides > * {
    float: left;
    margin: 0;
    box-sizing: border-box;
    tap-highlight-color: transparent;
    }

.peppermint.peppermint-active .peppermint-slides a:active,
.peppermint.peppermint-active .peppermint-slides a:active img {
    outline: none;
    }

.peppermint.peppermint-active,
.peppermint.peppermint-active .peppermint-dots,
.peppermint.peppermint-active .peppermint-slides,
.peppermint.peppermint-active .peppermint-slides > * {
    transform: translate3d(0,0,0);
    backface-visibility: hidden;
    }

.peppermint.peppermint-mouse .peppermint-slides {
    user-select: none;
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.peppermint.peppermint-mouse.peppermint-drag .peppermint-slides * {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}

/* default appearance styles */
.peppermint {
    }

.peppermint figure {
    margin: 0 0 1.5em 0;
    }

.peppermint figure img {
    vertical-align: top;
    max-width: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.peppermint figure figcaption {
    font-style: italic;
    color: #787575;
    font-size: 0.8em;
    margin-top: 0.5em;
    }

.peppermint.peppermint-active {
    margin-bottom: 1.5em;
    }

.peppermint-slides {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    }

/* dots */
ul.peppermint-dots {
    margin: 0.6em 0;
    padding: 0;
    text-align: center;
    list-style-type: none;
    }

.peppermint.peppermint-active ul.peppermint-dots {
    margin: 0 0 0.6em 0;
    }

.peppermint.peppermint-active .peppermint-slides + ul.peppermint-dots {
    margin: 0.6em 0 0 0;
    }

ul.peppermint-dots > li {
    display: inline-block;
    position: relative;
    padding: 0.5em 0.6em;
    width: 12px;
    height: 12px;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    /* ie7 fallback */
    *display: inline;
    *zoom: 1;
    }

ul.peppermint-dots > li.peppermint-mouse-clicked,
ul.peppermint-dots > li:active {
    outline: 0;
    }

ul.peppermint-dots > li > span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -4px 0 0 -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #9b9890;
    }

ul.peppermint-dots > li.peppermint-active-dot > span {
    margin: -6px 0 0 -6px;
    width: 10px;
    height: 10px;
    border: 1px solid #9b9890;
    background: transparent;
    }