// Grid
//
// Styleguide 5.0


// = Config
//-----------------------------------------------------------------------------//

$gutter: 20px !default;
$columns: 12 !default;

$fallback-gutter: 4%;

// = Grid
//-----------------------------------------------------------------------------//

$margin-left: ($gutter/2);
$margin-right: ($gutter/2);


$fallback-margin-left: ($fallback-gutter/2);
$fallback-margin-right: ($fallback-gutter/2);


.wrap {
    @include clearfix();
    margin: 0 auto;
    max-width: 1800px;
    width:100%;
    @media (min-width: $bp-medium) {
        padding: 0 $gutter;
        }
    @media (min-width: $bp-large) {
        padding: 0 $gutter * 2;
        }
    @media (min-width: $bp-xlarge) {
        padding: 0 $gutter * 8 0 $gutter * 4;
        }
    }


.col {  
    float: left;
    margin-left: $fallback-margin-left;
    margin-left: $margin-left;
    margin-right: $fallback-margin-right;
    margin-right: $margin-right;
    width: 100% - $fallback-gutter;
    width: calc(100% - #{$gutter});
    }

.col--centered {  
    float: none;
    margin-left:auto !important;
    margin-right:auto  !important;
    }

@mixin grid($class: 'col', $columns: $columns, $gutter: $gutter, $extend: '') {
     $gutter-half: ($gutter/2);
    .col {
        margin-left: ($fallback-gutter/2);
        margin-left: calc(#{$gutter-half});
        margin-right: ($fallback-gutter/2);
        margin-right: calc(#{$gutter-half});
            .row {
                margin-left: -($fallback-gutter/2);
                margin-left: -($gutter/2);
                margin-right: -($fallback-gutter/2);
                margin-right: -($gutter/2);
                }
        }

    .#{$class} {

        &--switch {
            float: right;
        }

        &--center {
            float: none;
            margin: 0 auto;
        }
    }

    @for $i from 1 through $columns  {

        $column_width: (($i / $columns) * 100%);

        .#{$class}-#{$i}#{$extend} {
            width: $column_width - $fallback-gutter;
            width: calc(#{$column_width} - #{$gutter});
        }

        // I need to test this
       .#{$class}--push-#{$i} {
            margin-left: $column_width + ($fallback-gutter/2);
            margin-left: calc(#{$column_width} + (#{$gutter}/2));
        }
    }
}


.row {
    @include clearfix;
    margin-left: 0;
    margin-right: 0;
    }

@include grid($class: 'small');

@media (min-width: $bp-small-medium) {
 //   @include grid($class: 'small-medium');
}
@media (min-width: $bp-medium) {
    @include grid($class: 'medium');
}
@media (min-width: $bp-large) {
    @include grid($class: 'large');
}
@media (min-width: $bp-xlarge) {
    @include grid($class: 'xlarge');
}
.push-1--medium {
    @media (min-width: $bp-medium) {
        margin-right: calc(8.33333% + #{$gutter});
        }
  }
.push-1--large {
    @media (min-width: $bp-large) {
        margin-right: calc(8.33333% + #{$gutter});
        }
  }
  .pull-1--medium {
    @media (min-width: $bp-medium) {
        margin-left: calc(8.33333% + #{$gutter});
        }
  }

.pull-2--medium {
    @media (min-width: $bp-medium) {
        margin-left: calc(8.33333% * 2 + #{$gutter});
        }
    }

.pull-1--large {
    @media (min-width: $bp-large) {
        margin-left: calc(8.33333% + #{$gutter});
        }
    }

.pull-2--large {
    @media (min-width: $bp-large) {
        margin-left: calc(8.33333% * 2 + #{$gutter});
        }
    }

.pull-3--large {
    @media (min-width: $bp-large) {
        margin-left: calc(8.33333% * 3+ #{$gutter});
        }
  }


.last-child-pull-right--large {
    @media (min-width: $bp-large) {
        .col:last-child {
            float:right;
            }
        }    
    }
