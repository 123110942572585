/* Lists
-------------------------------------------------------------- */
.dl-list {
	@include gamma;
	border-bottom:1px solid $light-grey-3;
	border-top:1px solid $light-grey-3;
	color:$dark-grey-1;
	padding:$baseline / 2 0;
	}

.dl-list__title {
	@include delta;
	color:$mid-grey-2;
	display:block;
	margin-bottom:$baseline / 8;
	}

.dl-list__content {
	@include gamma;
	display:block;
	margin-bottom:$baseline / 2;
	color:$dark-grey-2;
	}

.dl-list--horizontal {
	border:none;
	border-bottom:1px solid $light-grey-1;
	overflow:visible;
	position:relative;
	&:after {
		background-color:$light-grey-2;
		bottom:$baseline/ 4;
		content:'';
		position:absolute;
		top:$baseline/ 4;
		right:- $gutter / 2;
		width:1px;
		}
	}

.dl-list--intro {
	border:none;
	border-bottom:none;
	overflow:visible;
	padding: $baseline / 2 0;
	position:relative;
	&:nth-child(odd) {
		&:after {
			background-color:$light-grey-2;
			bottom:$baseline / 2;
			content:'';
			position:absolute;
			top:$baseline / 2;
			right: - $gutter / 2;
			width:1px;
			}
		}
	&:last-child{
		&:before{
			content:none;
			}
		}
	&:before {
		background-color:$light-grey-2;
		bottom:0;
		content:'';
		height:1px;
		left: 0;
		position:absolute;
		right: 0;
		}

	.dl-list__title {
		@include epsilon;
		}

	.dl-list__content {
		@include delta;
		margin-bottom:0;
		}

	}

.list {
	display:block;
	}

.list__item {
	border-bottom:1px dotted $light-grey-2;
	display:block;
	padding:$baseline / 2 0;
	}

.list__item--title-row {
	@include delta;
	color:$mid-grey-2;
	font-weight:bold;
	padding:$baseline / 2 $baseline / 4;
	text-transform:uppercase;
	}

.list__item--case-study {
	border-bottom:1px solid $light-grey-1;
	padding:0;
	}

.list__link--case-study {
	@include clearfix;
	@include gamma;
	color:$dark-grey-1;
	display:block;
	padding:$baseline / 2 $baseline / 4;
	transition: background-color 0.5s ease;
	&:hover {
		background-color:$light-grey-1;
		color:$dark-grey-3;
		}
	}






