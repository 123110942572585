.form {
    margin-bottom:$baseline * 2;
    }

.legend  {
    @include alpha;
    color:$dark-grey-3;
    display:block;
    font-weight:700;
    margin-bottom:$baseline * 1.5;
    padding:0;
    width:100%;
    }

.form-control-label {
    @include gamma;
    color:$dark-grey-1;
    display:block;
    margin-bottom: $baseline / 3;
    }

.form-control-label__help {
    @include epsilon;
    color:$mid-grey-1;

    }

.form-row {
    @include clearfix;
//    border-bottom:1px dotted $light-grey-3;
    margin-bottom:$baseline / 2;
    padding:$baseline / 2 0;
    position:relative;
    }

.form-row--static {
    margin-bottom:0;
    }

.form-row--btns {
    text-align:right;    
    .btn {
        margin-left: $baseline / 2;
        }
    }

* {
//    outline:1px solid red !important;
    }
.form-control {
    @include beta;
    background-color: $light-grey-1;
    background-color: transparent;
    border:none;
    border-bottom:1px solid $light-grey-3;
    border-radius:0;
    color:$dark-grey-1;
    font-family:$primary-font;
    margin-bottom:$baseline / 3;
    max-width:100%;
    padding:$baseline / 2.5 0;
    position:relative;
    transition: border-color 0.15s ease;
    width:100%;
    &:hover {
        border-color:$dark-grey-3;
        }
    &:focus {
        border-color:$dark-grey-3;
        color:$dark-grey-3;
        outline: none; 
        }
    }
.form-control--dob {
    max-width:$baseline * 2.75;
    width:auto;
    }

.form-tooltip {
    display:block;
    position:absolute;
    right:$baseline / 4;
    top:$baseline * 1.85;
    }

.col.relative .form-tooltip {
    top:$baseline * 1.35;
    }

.form-textarea {
    min-height:8rem;
    }

.form-select {
    display: block;
    cursor:pointer;
    position: relative;
    line-height:1.25rem;
    background:#fff;
    position: relative;
    z-index: 1;
    max-width:650px;
    .field {
        background:transparent;
        position: relative;
        z-index:2;
        &:focus {
            background:transparent;
            }
        }

    &:after {
        content:'';
        border-color: $mid-grey-2 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 2.5px;
        display: inline-block;
        height: 0;
        width: 0;
        position:absolute;
        right:15px;
        top:1.2rem;
        z-index:1;
        }
    }

@media screen and (min-width:0\0) and (min-resolution:.001dpcm) {
    //ie9 hack
    .form-select {
        padding-right:10px !important;
        }
    .form-select__icon {
        display:none;
        }
    }

.form-file {
    cursor:pointer;
    display: block;
    margin-bottom:$baseline * 1.5;
    position:relative;
    input {
        cursor:pointer;
        height:2rem;
        opacity:0;        
        width:100%;
        z-index:1;        
        &:focus:before {
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23000000%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M9%2016h6v-6h4l-7-7-7%207h4zm-4%202h14v2H5z%22/%3E%0A%3C/svg%3E');
            }
        }
    &:before {
        background-color:#fff;        
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23bbbbbb%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M9%2016h6v-6h4l-7-7-7%207h4zm-4%202h14v2H5z%22/%3E%0A%3C/svg%3E');
        background-repeat: no-repeat;
        background-position:right 20px center;
        border: 1px solid #fff;
        border-bottom:1px solid $mid-grey-1;
        content:'Choose file';
        cursor:pointer;        
        line-height:1.5rem;        
        padding:$baseline / 6 $gutter / 6;
        position:absolute;
        width:100%;
        }

    &:after {
        right:$gutter;
        }
    }

.form-file-name {
    @include gamma;
    display:block;
    line-height:1.4;    
    padding:.5rem 0 0 0;
    }

.checkbox label, 
.radio label {
    color:$dark-grey-2;
    cursor: pointer;
    margin-bottom: 0;
    padding-left: 1.25rem;
    }

.checkbox input[type="checkbox"], 
.checkbox-inline input[type="checkbox"], 
.radio input[type="radio"], 
.radio-inline input[type="radio"] {
    margin-left: -1.25rem;
    margin-top: 0.25rem;
    position: absolute;
    }

.checkbox-inline, 
.radio-inline {
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    margin-bottom: 0;
    padding-left: 1.25rem;
    position: relative;
    vertical-align: middle;
    }

.checkbox input[type="checkbox"], 
.checkbox-inline input[type="checkbox"], 
.radio input[type="radio"], 
.radio-inline input[type="radio"] {
    margin-left: -1.25rem;
    margin-top: 0.25rem;
    position: absolute;
    }

.checkbox-inline+.checkbox-inline, 
.radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: .75rem;
    }

.has-success .checkbox, 
.has-success .checkbox-inline, 
.has-success .form-control-label, 
.has-success .radio, 
.has-success .radio-inline, 
.has-success .text-help, 
.has-success.checkbox label, 
.has-success.checkbox-inline label, 
.has-success.radio label, 
.has-success.radio-inline label {
    color: $success-color;
    }

.has-success .form-control--success {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==");
    }

.has-success .form-control {
    border-color: $success-color;
    }

.form-control--danger, 
.form-control--success, 
.form-control--warning {
    background-position: right 0.5625rem center;
    background-repeat: no-repeat;
    background-size: 1.4625rem 1.4625rem;
    padding-right: 2.25rem;
    }

.has-warning .checkbox, 
.has-warning .checkbox-inline, 
.has-warning .form-control-label, 
.has-warning .radio, 
.has-warning .radio-inline, 
.has-warning .text-help, 
.has-warning.checkbox label, 
.has-warning.checkbox-inline label, 
.has-warning.radio label, 
.has-warning.radio-inline label {
    color: #f0ad4e;
    }

.has-warning .form-control--warning {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+");
    }

.has-warning .form-control {
    border-color: #f0ad4e;
    }

.has-danger .checkbox, 
.has-danger .checkbox-inline, 
.has-danger .form-control-label, 
.has-danger .radio, 
.has-danger .radio-inline, 
.has-danger .text-help, 
.has-danger.checkbox label, 
.has-danger.checkbox-inline label, 
.has-danger.radio label, 
.has-danger.radio-inline label {
    color: #d9534f;
    }

.has-danger .form-control--danger {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=");
    }

.has-danger .form-control {
    border-color: #d9534f;
    }



.c-select {
    -moz-appearance: none;
    background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAMAAACzvE1FAAAADFBMVEUzMzMzMzMzMzMzMzMKAG/3AAAAA3RSTlMAf4C/aSLHAAAAPElEQVR42q3NMQ4AIAgEQTn//2cLdRKppSGzBYwzVXvznNWs8C58CiussPJj8h6NwgorrKRdTvuV9v16Afn0AYFOB7aYAAAAAElFTkSuQmCC") no-repeat scroll right 0.75rem center / 8px 10px;
    border: 1px solid #ccc;
    color: #55595c;
    display: inline-block;
    max-width: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    vertical-align: middle;
    }

 .c-select--inline {
    display:block;
    margin-bottom:$baseline / 1.5;
    @media (min-width: $bp-medium) {
        display:inline-block;
        margin-bottom:0;
        margin-right:$baseline / 1.5;
        }
    }

.file {
    cursor: pointer;
    display: inline-block;
    height: 2.5rem;
    position: relative;
    }

.file input {
    margin: 0;
    min-width: 14rem;
    opacity: 0;
    outline:1px solid red;
    width:80%;
    }

.file-custom::before {
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 0 0.25rem 0.25rem 0;
    bottom: -0.075rem;
    color: #555;
    content: "Browse";
    display: block;
    height: 2.5rem;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    position: absolute;
    right: -0.075rem;
    top: -0.075rem;
    z-index: 6;
    }

.file-custom::after {
    content: "Choose file...";
    }

.file-custom {
    -moz-user-select: none;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    color: #555;
    height: 2.5rem;
    left: 0;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    }


.c-input {
    color: #555;
    cursor: pointer;
    display: inline;    
    position: relative;
    padding-left: 1.5rem;
    }

.c-inputs-stacked .c-input::after {
    content: "";
    display: block;
    margin-bottom: 0.25rem;
    }

.c-inputs-stacked .c-input {
    display: inline;
    }

.c-input>input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    }

.c-checkbox input:checked~.c-indicator {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiP…43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=");
}

.c-checkbox input:checked ~ .c-indicator {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=");
}

.c-input>input:checked~.c-indicator {
    color: #fff;
    background-color: #0074d9;
    }

.c-checkbox .c-indicator {
    border-radius: .25rem;
    }

.c-indicator {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    font-size: 65%;
    line-height: 1rem;
    color: #eee;
    text-align: center;
    user-select: none;
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 50% 50%;
    background-size: 50% 50%;
    }


.c-radio input:checked ~ .c-indicator {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==");
    }

.c-input > input:checked ~ .c-indicator {
    background-color: #0074d9;
    color: #fff;
    }

.c-radio .c-indicator {
    border-radius: 50%;
    }









.btn--form {
    display: inline-block;
    font-family:$body-font-family;
    font-size:15px;
    font-weight:normal;
    height:$baseline * 2;
    height:$baseline * 2;
    margin:0 0 $baseline 0;
    outline: none;
    padding: 0 $baseline * .66;
    width:auto;
    }

.form--cta {
    input,
    select,
    textarea {
        background-color: transparent;
        border:none;
        border-bottom: 1px solid $mid-grey-3;
        color:$light-grey-1;
        margin-bottom:spacer(.33);
        padding:$baseline * .66 0;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0);
        -webkit-transition: -webkit-box-shadow 0.15s, border-color 0.15s ease-in-out;
        -moz-transition: -moz-box-shadow 0.15s, border-color 0.15s ease-in-out;
        transition: box-shadow 0.15s, border-color 0.15s ease-in-out; 
        }
        input:hover,
        select:hover,
        textarea:hover {
            -webkit-box-shadow: 0 0 3px transparent;
            -moz-box-shadow: 0 0 3px transparent;
            box-shadow: 0 0 3px transparent;
            border-color: $mid-grey-1;
            }
                
        input[type="text"]:focus,
        input[type="password"]:focus,
        input[type="date"]:focus,
        input[type="datetime"]:focus,
        input[type="datetime-local"]:focus,
        input[type="month"]:focus,
        input[type="week"]:focus,
        input[type="email"]:focus,
        input[type="file"]:focus,
        input[type="number"]:focus,
        input[type="search"]:focus,
        input[type="tel"]:focus,
        input[type="time"]:focus,
        input[type="url"]:focus,
        select:focus,
        textarea:focus {    
            border-color: $primary-color;
            background-color: transparent;
            outline: none; 
            -webkit-box-shadow: 0 3px 3px -3px $primary-color;
            -moz-box-shadow: 0 3px 3px -3px $primary-color;
            box-shadow: 0 3px 3px -3px $primary-color;
            }
        
        input[type="text"][disabled],
        input[type="password"][disabled],
        input[type="date"][disabled],
        input[type="datetime"][disabled],
        input[type="datetime-local"][disabled],
        input[type="month"][disabled],
        input[type="week"][disabled],
        input[type="email"][disabled],
        input[type="number"][disabled],
        input[type="search"][disabled],
        input[type="tel"][disabled],
        input[type="time"][disabled],
        input[type="url"][disabled],
        select[disabled],
        textarea[disabled] {
            background-color: #dddddd;
            }

    input[type="checkbox"],
    input[type="radio"] {
        background-color:transparent;
        border:none;
        clear:both;
        display:block;
        float:left;
        margin-right:spacer(.5);
        margin-top:spacer(.1);
        padding:0;
        width:auto;
        }
    input.btn.btn--primary {
        background-color:$primary-color;
        border-color:shade($primary-color, 25%);
        color:#fff;
        &:hover,
        &:focus,
        &.active,
        &:active {
            background-color:tint($primary-color, 25%);
            color:#fff;
            }
        &:visited {
            color:#fff;
            }
        }
    textarea {
        height:auto;
        min-height:0;
        }
    }

.form-global-search {
    display:block;
    margin-top:$baseline * 2;
    position:relative;
    }

.form-global-search__input {
    background-color:transparent;
    box-shadow: none;
    border:none;
    border-bottom:1px solid $light-grey-3;
    color:$dark-grey-1;
    font-family:$primary-font-light;
    font-size:1rem;
    font-weight:200;
    outline:none;
    padding:$baseline / 2 $baseline * 3 $baseline / 2 0;
    width:100%;
    @media (min-width: $bp-medium) {
        font-size:3rem;
        padding:$baseline / 1.5 $baseline * 12 $baseline baseline;
        }
    &:focus {
        border-bottom:2px solid $light-grey-3;
        }
    }

.form-global-search__btn {
    box-shadow: none;
    background-color:transparent;
    border:none;
    color:$light-grey-3;
    font-size:1rem;
    outline:none;  
    padding:$baseline / 2;
    position:absolute;
    right:0;
    top:0;
    &:hover {
        color:$dark-grey-3;
        text-decoration:none;
        }
    @media (min-width: $bp-medium) {
        font-size:3rem;
        padding:$baseline / 1.5 $baseline $baseline;
        }
    }

.search-underline {
    border-bottom:2px solid $primary-color;
    bottom:0;
    font-size: 3rem;
    height:0;
    left:0rem;    
    max-width:calc(100% - 4.5rem);
    overflow-y:hidden;
    padding:0.75rem 0;
    position:absolute;
    }


