.js-wall-item {
    will-change: height;
    }

.js-wall-trigger {
    background:transparent;
    display:block;
    cursor:pointer;
    text-align:left;
    width:100%;    
    }

.js-wall-child {
    background: $light-grey-1; 
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    max-height:0;
    overflow: hidden;
    position: absolute;        
    visibility: hidden;    
    width: 1px;    
    z-index: 2;
    }

.js-wall-button-close,
.js-wall-button-previous,
.js-wall-button-next {
    color:$dark-grey-3;
    cursor:pointer;
    font-size:2rem;
    position: absolute;
    top:100px;
    z-index:1;
    }

.js-wall-button-close {
    right:20px;
    top:20px;    
    }

.js-wall-button-previous {
    left:30px;
    }

.js-wall-button-next {
    right:30px;
    }

.js-wall-panel {
    background: $light-grey-2;
    clear:left;    
    left:0;
    overflow:hidden;
    position: absolute;    
    right:0;
    top:0;
    visibility: hidden;
    width:auto;
    }

.js-wall--on .js-wall-panel {
    visibility: visible;
    z-index:1;
    }

.js-wall-panel-inner {
    opacity:0;
    transition:opacity 260ms ease;    
    will-change:opacity;
    }

.js-is-animating.js-wall--on .js-wall-panel-inner {
    opacity:0;
    transition:opacity 260ms ease;
    }

.js-wall--on .js-wall-panel-inner {
    opacity:1;
    }

.js-wall-trigger[aria-expanded="true"]  {
    position:relative;
    &:after {
        display:block;
        }

    .staff-teaser__img {
        opacity:.6;
        }
    }

.wall__trigger {
    outline:none !important;
    .staff-teaser__img {
        opacity:1;
        transition:opacity 1s ease;
        }
    &:hover {
        .staff-teaser__img {
            opacity:.6;
            }
        }
    &:after {
        border-style: solid;
        border-width: $baseline $baseline 0 $baseline;
        border-color: #fff transparent transparent;
        bottom:-$baseline;
        content:'';
        display:none;
        height:0;
        margin-right:-$baseline;
        right:50%;
        position:absolute;
        width:0;
        z-index:2;
        }
    }


