.block {
    display:block;
    }

.inline-block {
    display:inline-block !important;
    width:auto !important;
    }

.absolute {
    position:absolute;
    }

.relative {
    position:relative;
    }

.centered {
    display:block;
    margin-left:auto;
    margin-right:auto;
    }

.fixed {
    @media screen and (min-width: $bp-large) {
        margin-left: auto;
        margin-right: auto;
        position: fixed;
        top: 0;
        width: 100%;
        z-index:10;
        }
    }

.top {
    top:0;
    }

.right {
    right:0;
    }

.pull-right {
    float:right !important;
    }

.pull-right--medium {
    @media (min-width: $bp-medium) {
        float:right !important;
        }
    }

.pull-right--large {
        @media screen and (min-width: $bp-large) {
            float:right !important;
        }
}

.pull-left {
    float:left !important;
    }

.pull-left--medium {
        @media screen and (min-width: $bp-medium) {
            float:left;
        }
}

.pull-left--large {
        @media screen and (min-width: $bp-large) {
            float:left;
        }
}

.vertical-align {
    @include vertical-align;
    }


.push--top--small-screen { 
    @media(max-width:$bp-large) {
        margin-top:$baseline !important;
        }
}

// = Add or remove Margin
//-----------------------------------------------------------------------------//

.push { margin:$baseline !important; }
.push--top { margin-top:$baseline !important; }
.push--right { margin-right:$baseline !important; }
.push--bottom { margin-bottom:$baseline !important; }
.push--left { margin-left:$baseline !important; }
.push--ends { margin-top:$baseline !important; margin-bottom:$baseline !important; }
.push--sides { margin-right:$baseline !important; margin-left:$baseline !important; }

.push-half { margin:$baseline / 2 !important; }
.push-half--top { margin-top:$baseline / 2 !important; }
.push-half--right { margin-right:$baseline / 2 !important; }
.push-half--bottom { margin-bottom:$baseline / 2 !important; }
.push-half--left { margin-left:$baseline / 2 !important; }
.push-half--ends { margin-top:$baseline / 2 !important; margin-bottom:$baseline / 2 !important; }
.push-half--sides { margin-right:$baseline / 2 !important; margin-left: $baseline / 2 !important; }

.push-double--bottom { margin-bottom:$baseline * 2 !important; }
.push-double--ends { margin-top:$baseline * 2 !important; margin-bottom:$baseline * 2 !important; }
.push-double--sides { margin-right:$baseline * 2 !important; margin-left:$baseline * 2 !important; }
.push-double--top { margin-top:$baseline * 2 !important; }

.push-quarter--bottom { margin-bottom:$baseline / 4 !important; }

.flush { margin:0!important; }
.flush--top { margin-top: 0!important; }
.flush--right { margin-right: 0!important; }
.flush--bottom { margin-bottom:0!important; }
.flush--left { margin-left: 0!important; }
.flush--ends { margin-top: 0!important; margin-bottom:0!important; }
.flush--sides { margin-right: 0!important; margin-left:  0!important; }


// = Add or remove Padding
//-----------------------------------------------------------------------------//

.soft { padding:$baseline !important; }
.soft--top { padding-top:$baseline !important; }
.soft--right { padding-right:$baseline !important; }
.soft--bottom { padding-bottom:$baseline !important; }
.soft--left { padding-left:$baseline !important; }
.soft--ends { padding-top:$baseline !important; padding-bottom:$baseline !important; }
.soft--sides { padding-right:$baseline !important; padding-left: $baseline !important; }

.soft--right--large { 
    @media (min-width: $bp-large) {
        padding-right:$baseline !important; 
        }
    }

.soft-double { 
    padding:$baseline !important; 
    @media (min-width: $bp-large) {
        padding:$baseline * 2 !important; 
        }
    }
.soft-double--ends { 
    padding-top:$baseline !important; 
    padding-bottom:$baseline !important; 
    @media (min-width: $bp-large) {
        padding-top:$baseline * 2 !important; 
        padding-bottom:$baseline * 2 !important; 
        }
    }
.soft-double--top { 
    padding-top:$baseline !important; 
    @media (min-width: $bp-large) {
        padding-top:$baseline * 2 !important; 
        }
    }

.soft-double--bottom { 
    padding-bottom:$baseline !important; 
    @media (min-width: $bp-large) {
        padding-bottom:$baseline * 2 !important; 
        }
    }

.soft-double--sides { 
    padding-right:$baseline !important; 
    padding-left: $baseline !important; 
    @media (min-width: $bp-large) {
        padding-right:$baseline * 2 !important; 
        padding-left: $baseline * 2 !important; 
        }
    }

.soft-half { padding:$baseline / 2 !important; }
.soft-half--top { padding-top:$baseline / 2 !important; }
.soft-half--right { padding-right:$baseline / 2 !important; }
.soft-half--bottom { padding-bottom:$baseline / 2 !important; }
.soft-half--left { padding-left:$baseline / 2 !important; }
.soft-half--ends { padding-top:$baseline / 2 !important; padding-bottom:$baseline / 2 !important; }
.soft-half--sides { padding-right:$baseline / 2 !important; padding-left: $baseline / 2 !important; }

.soft-quarter { padding:$baseline / 4 !important; }

.hard { padding: 0!important; }
.hard--top { padding-top:0!important; }
.hard--right { padding-right: 0!important; }
.hard--bottom { padding-bottom:0!important; }
.hard--left { padding-left:0!important; }
.hard--ends { padding-top:0!important; padding-bottom:0!important; }
.hard--sides { padding-right: 0!important; padding-left:  0!important; }


/*HAAAAACK*/
.gm-style {
    z-index:10000 !important;
    }
    
.on--navigation {
    z-index:10 !important;
    }

.width-100 {
    width:100%;  
} 
