// Path alias
	$assetPath:                '/content';
	$uiImgPath:                '#{$assetPath}/img';
	$fontPath:                 '#{$assetPath}/fonts';


/*=Additional Grey UI Colours
------------------------------------------------*/
	$light-grey-1: #f5f5f5;
	$light-grey-2: #e6e6e6;
	$light-grey-3: #cccccc;
	$mid-grey-1: #b3b3b3;
	$mid-grey-2: #999999;
	$mid-grey-3: #808080;
	$dark-grey-1: #666666;
	$dark-grey-2: #4d4d4d;
	$dark-grey-3: #333333;
	$off-black:#1a1a1a;
	$off-white:#e7e6dd;

// Colors
// -------------------------
	$body-font-color:       #191919 !default;
	$brand-primary-color:	#006276 !default;
	$primary-color:		$dark-grey-1 !default;
	$secondary-color:	#444 !default;
	
// = Additional UI Colours
//-----------------------------------------------------------------------------//

    $info-color: $mid-grey-3;
    $success-color: #5cb85c;
    $warning-color: #f0ad4e;
    $danger-color: #d9534f;

// Type
// --------------------------------------------------
// Font weights
$thin:                      100;
$light:                     300;
$regular:                   400;
$semibold:                  600;
$bold:                      700;
$black:                     800;

$baseline:                  1.5rem !default;
$base-font-family:          'Neue Haas Grotesk W01', sans-serif !default;
$base-font-weight:          $regular !default;
$base-font-size:            100% !default;
$base-line-height:          1.5 !default;

$body-font-family:       	'Neue Haas Grotesk W01', sans-serif !default;
$bold-font-family:          'Neue Haas Grotesk W01', sans-serif !default;
$primary-font:       		'Neue Haas Grotesk W01', sans-serif !default;
$secondary-font:       		'Neue Haas Grotesk W01', sans-serif !default;
$primary-font-light:		'Neue Haas Grotesk W01 Disp', sans-serif !default;
$primary-font-bold:			'Neue Haas Grotesk W01', sans-serif !default;


// Layout
// --------------------------------------------------
$max-container-width:       1400px !default;

// Shared
$border-radius:             2px !default;
$border-color:              2px !default;
$border-width:              1px !default;

// Breakpoints
// --------------------------------------------------
$bp-small-medium:          37.5em !default;
$bp-medium:                 48em !default;
$bp-medium-large:           54em !default;
$bp-large:                  60em !default;
$bp-large-xlarge:           70em !default;
$bp-xlarge:                 90.1em !default;
$bp-xxlarge:                 100em !default;




